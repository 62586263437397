import styled from 'styled-components';
import { Theme, getColor } from 'styles/theme';

export const CheckMark = styled.span<{ theme: Theme }>`
  margin-bottom: 4px;
  display: inline-block;
  width: 12px;
  height: 21px;
  position: relative;
  transform: rotate(225deg);
  
  &::before, &::after {
    content: '';
    position: absolute;
    background-color: ${getColor('iconWhite')};
    border-radius: 3px;
  }

  &::before {
    width: 3px;
    height: 100%;
  }

  &::after {
    height: 3px;
    width: 100%;
  }
`;

export default CheckMark;
