import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme, getBodyStyle } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('surfacePrimary')};
  padding: 20px;
  margin: 0 -20px;

  @media (${breakpoint.mdMin}) {
    padding: 40px 32px;
    display: flex;
    gap: 40px;
    margin: 0 -32px;

    .adaptive-img {
      min-width: 300px;
      max-height: 165px;
    }
  }

  @media (${breakpoint.lgMin}) {
    margin: 0 -24px;

    .adaptive-img {
      min-width: 450px;
      max-height: 250px;
    }
  }

  @media (${breakpoint.xlMin}) {
    margin: 0 -48px;
    padding: 40px 42px;
  }

  @media (${breakpoint.xxlMin}) {
    margin: 0 -40px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-bottom: 16px;
`;

export const Content = styled.div<{ theme: Theme }>`
  width: 100%;
  ${getBodyStyle('graphikCompact', { default: 'default' }, 'regular')};
`;
