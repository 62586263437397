/* eslint-disable import/named */
import { useEffect, useState } from 'react';
import { FranchiseListSearch, FranchisePage, Grid } from 'interfaces/content/Ranking';
import { MenuItemProps } from 'interfaces/navigation/Header';
import SectionTitle from 'components/Globals/SectionTitle';
import Accordion from 'components/Globals/Accordion';
import { RANKING_V2_PAGE, FAQ_ACCORDION } from 'constants/testsIds/rankingV2Page';
import RankingAnalytics from 'components/Ranking/RankingAnalytics';
import VisualizeF500 from 'components/Ranking/VisualizeF500';
import MoreRankings from 'components/Ranking/MoreRankings';
import FeaturedImage from 'components/Ranking/FeatureImage';
import RankingList from 'components/Ranking/RankingList';
import InContent from 'components/Ad/AdSlot/InContent';
import StnVideo from 'components/StnVideo';
import VodVideo from 'components/VodVideo';
import RankingSearchPage from 'pages/RankingSearch/RankingSearchPage';
import { useRouter } from 'next/router';
import MethodologyContent from 'components/Ranking/Methodology';
import HighlightedCompanies from 'components/Ranking/HighlightsCompany';
import GridContentModuleV2 from 'components/GridContentModuleV2';
import * as S from './RankingCompanyFranchisePageStyles';

interface RankingFranchiseProps {
  franchise: FranchisePage;
  franchiseSearch: FranchiseListSearch;
  subDomain?: string;
  subMenu?: MenuItemProps[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RankingCompanyFranchisePage = ({ franchise, subDomain, subMenu, franchiseSearch }: RankingFranchiseProps) => {
  const {
    contentModules,
    faq,
    lists,
    listTeaser,
    listType,
    title,
    featuredImage,
    description,
    callsToAction,
    highlightedCompanies,
    methodology,
    slug,
  } = franchise;
  const [expandedSections, setExpandedSections] = useState<boolean[]>([true]);
  const { stnVideoId } = franchise;
  const { vodVideoId } = franchise;
  const showVideo = stnVideoId || vodVideoId;

  const router = useRouter();
  const [initialSearch, setInitialSearch] = useState('');
  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | boolean }>({});
  const isVizualizeValid = () => {
    let isValid = true;
    Object.entries(contentModules.feature).forEach(([, value]) => {
      if (value === null) isValid = false;
    });
    return isValid;
  };
  const hasVizualize = contentModules.feature && Object.keys(contentModules.feature).length > 0 && isVizualizeValid();
  const hasCoverage = contentModules.grid.topics && contentModules.grid.topics.length > 0;
  const coverageSection = {
    title: contentModules.grid.title,
    topics: contentModules.grid?.topics?.map((topic) => ({
      ...topic,
      image: topic.image || '/icons/ImagePlaceholder.svg',
    })),
  } as Grid;

  useEffect(() => {
    const queryString = new URLSearchParams(router.asPath.split('?')[1] || '');

    setInitialSearch(queryString.get('Name') || '');
    queryString.delete('Name');

    const filters: { [key: string]: string | boolean } = {};
    queryString.forEach((value, key) => {
      if (value === 'true' || value === 'false') {
        filters[key] = value === 'true';
      } else {
        filters[key] = value;
      }
    });
    setInitialFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchiseSearch]);

  const toggleFaqExpanded = (index: number) => {
    setExpandedSections(() => {
      const updatedSections = [false];
      updatedSections[index] = !updatedSections[index];
      return updatedSections;
    });
  };

  return (
    <S.FranchiseWrapper>
      <FeaturedImage
        image={featuredImage}
        description={description}
        title={title}
        callToAction={callsToAction}
        hasMethodology={methodology?.length !== undefined && methodology.length > 0}
      />

      <RankingList
        lists={lists}
        title={title}
        listTeaser={listTeaser}
        listType={listType}
        year={franchiseSearch.year}
      />

      {showVideo && (
        <S.VideoContainer>
          <S.VideoWrapper data-cy={RANKING_V2_PAGE.VIDEO}>
            {stnVideoId ? (
              <StnVideo
                videoId={stnVideoId}
                dataCy={RANKING_V2_PAGE.STN_VIDEO}
              />
            ) : (
              vodVideoId && (
                <VodVideo
                  dataCy={RANKING_V2_PAGE.VIDEO}
                  videoPosterCy={RANKING_V2_PAGE.VIDEO_POSTER}
                  videoId={vodVideoId}
                />
              )
            )}
          </S.VideoWrapper>
        </S.VideoContainer>
      )}

      {highlightedCompanies.length > 0 && <HighlightedCompanies highlights={highlightedCompanies} />}
      <InContent id={Math.random()} />

      <S.SearchWrapper id='search-section'>
        <SectionTitle
          tag='h2'
          text={`The Full ${franchise.title} List`}
          variant='border'
          titleDataCy={RANKING_V2_PAGE.FILTER_TITLE}
        />
        <RankingSearchPage
          franchiseList={franchiseSearch}
          initialFilters={initialFilters}
          years={franchiseSearch.years}
          initialYear={franchiseSearch.year}
          initialSearch={initialSearch}
          showTitle={false}
          initialRowCount={25}
        />
      </S.SearchWrapper>

      {contentModules.relatedRankings.length > 0 && <MoreRankings relatedRankings={contentModules.relatedRankings} />}

      <InContent id={Math.random()} />

      {hasVizualize && (
        <VisualizeF500
          feature={contentModules.feature}
          mainTitle={`Visualize the ${franchise.title}`}
        />
      )}

      {hasCoverage && (
        <GridContentModuleV2
          grid={coverageSection}
          dataCy={RANKING_V2_PAGE.COVERAGE_MODULE}
        />
      )}

      {methodology?.length && (
        <MethodologyContent
          methodology={methodology}
          mainTitle={`Methodology for ${title}`}
          idProp='methodology'
        />
      )}

      {(hasVizualize || hasCoverage || methodology?.length) && <InContent id={Math.random()} />}

      {(slug === 'fortune500' || slug === 'global500') && <RankingAnalytics />}

      {faq.length > 0 && (
        <S.FaqSection data-cy={FAQ_ACCORDION.WRAPPER}>
          <SectionTitle
            tag='h2'
            text='Frequently Asked Questions'
            variant='border'
            titleDataCy={RANKING_V2_PAGE.FAQ_TITLE}
          />
          <S.AccordionWrapper>
            {faq.map((item, index) => (
              <Accordion
                index={index}
                title={item.question}
                key={`${item}`}
                onClick={() => toggleFaqExpanded(index)}
                isExpanded={expandedSections[index] || false}
                className='ranking-franchise'
                dataCy={FAQ_ACCORDION}
              >
                {item.answer}
              </Accordion>
            ))}
          </S.AccordionWrapper>
        </S.FaqSection>
      )}
    </S.FranchiseWrapper>
  );
};

export default RankingCompanyFranchisePage;
