import SectionTitle from 'components/Globals/SectionTitle';
import Tabs from 'components/Globals/Tabs';
import { TabItem } from 'interfaces/content/Ranking';
import RawHtml from 'utils/miscUtils/rawHtml';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import * as S from './MethodologyStyles';

export interface Props {
  methodology: TabItem[];
  mainTitle: string;
  idProp: string;
}

const MethodologyContent = ({ idProp, methodology, mainTitle }: Props) => {
  const methodologies = methodology;

  if (methodologies.length === 0) {
    return null;
  }

  return (
    <S.MethodologyWrapper id={idProp}>
      <SectionTitle
        tag='h2'
        text={mainTitle}
        variant='border'
        titleDataCy={RANKING_V2_PAGE.METHODOLOGY_TITLE}
      />
      <Tabs
        enableTabsSlider
        config={methodologies.map(({ content, title }) => ({
          children: <>{RawHtml({ html: content })}</>,
          title,
        }))}
        defaultIndex={0}
        dropdownMobile
        tabDataCy={RANKING_V2_PAGE.TABS}
        contentDataCy={RANKING_V2_PAGE.CONTENT}
        variant='default'
      />
    </S.MethodologyWrapper>
  );
};

export default MethodologyContent;
