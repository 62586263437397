/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export const CheckmarkBadge = () => (
  <svg 
    width='24' 
    height='24' 
    viewBox='0 0 24 24' 
    fill='none'
    xmlns='http://www.w3.org/2000/svg' 
  >
    <path 
      d='M9.02301 21.23L7.35301 18.415L4.17701 17.731L4.48801 14.454L2.34601 12L4.48801 9.54604L4.17701 6.26904L7.35401 5.58504L9.02401 2.76904L12 4.02704L14.977 2.76904L16.647 5.58504L19.823 6.26904L19.512 9.54604L21.654 12L19.512 14.454L19.823 17.731L16.646 18.415L14.976 21.231L12 19.973L9.02301 21.23ZM10.95 14.858L15.908 9.90004L15.2 9.18004L10.95 13.43L8.80001 11.292L8.09201 12L10.95 14.858Z' 
      fill='#007B9D'
    />
  </svg>
);