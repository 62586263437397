import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { breakpoint } from 'styles/globals';
import styled from 'styled-components';

export const Wrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;

  .title-analytics {
    margin-bottom: 24px;
      
    @media(${breakpoint.mdMin}) {
      font-size: 2rem;
    }
  }
  
  .explore-button {
      border: 1px solid ${getColor('neutralLightGray3')};
      font-size: 14px;
      margin: auto;
      
      @media(${breakpoint.mdMin}) {
        font-size: 20px;
      }
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  @media(${breakpoint.mdMin}) {
    flex-direction: row;
    gap: 24px;
    margin-bottom: 48px;
  }

  @media(${breakpoint.hybridMin}) {
    gap: 40px;
  }
`;

export const Card = styled.div<{ theme: Theme }>`
  padding: 16px;
  background: ${getColor('surfaceWhite')};
  box-shadow: 4px 4px 20px 0px ${getColor('neutralLightGray3')}40;

  .link-analytics {
    display: block;
    text-align: right;
  }
`;

export const Content = styled.div`
  display: flex;
  padding-bottom: 17px;
  gap: 16px;
`;

export const ImageWrapper = styled.div`
  min-width: 55px;
  width: 55px;

  @media(${breakpoint.lgMin}) {
    min-width: 88px;
    width: 88px;
  }
`;

export const Title = styled.span<{ theme: Theme }>`
  display: block;
  padding-bottom: 4px;
  ${getBodyStyle('graphikCond', { default: 'medium', lgMin: 'xlarge' }, 'semiBold')}
`;

export const Description = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'regular')}
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media(${breakpoint.lgMin}) {
    -webkit-line-clamp: 2;
  }
`;
