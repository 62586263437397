import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  padding: 8px;
  position: relative;
  box-shadow: 0 0 16px 3px ${getColor('neutralDarkGray')}10;

  @media (${breakpoint.xlMin}) {
    padding: 16px;
  }

  .more {
    font-weight: 600;
  }

  .more-years {
    top: 94%;
    box-shadow: 5px 5px 8px 0 ${getColor('neutralLightGray3')}50;
    border: 1px solid ${getColor('neutralLightGray3')}30;
    overflow: auto;
    align-items: center;
    width: 120px;

    @media (${breakpoint.xlMin}) {
      top: 90%;
    }

    > .more-year {
      ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};

      &:hover {
        color: ${getColor('textLink')};
      }
    }
  }
`;

export const HeaderWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${getColor('borderSecondary')};
  padding-bottom: 16px;

  @media (${breakpoint.xlMin}) {
    gap: 16px;
  }
`;

export const ImageContainer = styled.div<{ theme: Theme }>`
  width: 86px;

  @media (${breakpoint.lgMin}) {
    width: 104px;
  }
`;

export const TitleContainer = styled.div<{ theme: Theme }>`
  display: grid;
  gap: 4px;

  .title {
    ${getBodyStyle('graphikCond', { default: 'xlarge' }, 'semiBold')};
    text-align: left;
    text-decoration: none;
    font-size: 1.25rem;

    &:hover {
      color: ${getColor('textLink')};
    }

    @media (${breakpoint.xlMin}) {
      font-size: 1.5rem;
    }
  }

  .button {
    margin: 0;
    justify-content: left;
    font-size: 16px;
  }
`;
