import Link from 'components/Globals/Base/Link';
import { NextCustomImage } from 'components/Image';
import { Ranking, Year } from 'interfaces/content/Ranking';
import YearList from 'components/RankingsList/RankingsListItem/YearList';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import * as S from './CardItemStyles';

interface CardItemProps extends Ranking {
  className?: string;
  hasArrow?: boolean;
}

const CardItem = ({
  title,
  slug,
  years,
  image,
  imageAlt,
  buttonTitle,
  postId,
  className = '',
  trackingData,
  hasArrow = false,
}: CardItemProps) => {
  const yearsDesc = [...years].sort((aYear: Year, bYear: Year) => Number(bYear.year) - Number(aYear.year));
  const latestYear: Year = yearsDesc[0];

  const isFullUrl = (str: string): boolean => {
    const createUrl = (url: string): URL | null => {
      try {
        return new URL(url);
      } catch {
        return null;
      }
    };

    return createUrl(str) !== null;
  };

  let itemHref;
  if (latestYear.year && !isFullUrl(slug)) {
    const slugWithTrailingSlash = slug.endsWith('/') ? slug : `${slug}/`;
    itemHref = `${slugWithTrailingSlash}${latestYear.year}/`;
  } else {
    itemHref = slug;
  }

  return (
    <S.Wrapper
      className={className}
      data-cy={RANKING_V2_PAGE.MORE_RANKINGS_ITEM}
    >
      <S.HeaderWrapper>
        <S.ImageContainer>
          {image && (
            <Link
              href={slug}
              dataCy={RANKING_V2_PAGE.MORE_RANKINGS_IMAGE_LINK}
              trackingData={
                trackingData && {
                  ...trackingData,
                }
              }
            >
              <NextCustomImage
                className='image'
                width='164'
                height='455'
                src={`${image.split('?')[0]}?w=700`}
                alt={imageAlt!}
                data-cy={RANKING_V2_PAGE.MORE_RANKINGS_IMAGE}
              />
            </Link>
          )}
        </S.ImageContainer>
        <S.TitleContainer>
          <Link
            href={slug}
            className='title'
            dataCy={RANKING_V2_PAGE.MORE_RANKINGS_TITLE}
            trackingData={
              trackingData && {
                ...trackingData,
              }
            }
          >
            {title}
          </Link>
          <Link
            className='button'
            type='tertiaryButton'
            size={{ default: 'large' }}
            href={itemHref}
            hasRightArrow={hasArrow}
            dataCy={RANKING_V2_PAGE.MORE_RANKINGS_BUTTON}
            trackingData={
              trackingData && {
                ...trackingData,
              }
            }
          >
            {buttonTitle ?? `view ${latestYear.year || 'in'} list`}
          </Link>
        </S.TitleContainer>
      </S.HeaderWrapper>
      <YearList
        years={years}
        listId={postId}
        className='years'
      />
    </S.Wrapper>
  );
};

export default CardItem;
