import SectionTitle from 'components/Globals/SectionTitle';
import Link from 'components/Globals/Base/Link';
import { NextCustomImage } from 'components/Image';
import { RANKING_ANALYTICS } from 'constants/testsIds/rankingV2Page';
import { analyticsItems } from 'lib/mockData/Ranking/analyticsItems';
import * as S from './RankingAnalyticsStyles';

const RankingAnalytics = () => (
  <S.Wrapper data-cy={RANKING_ANALYTICS.WRAPPER}>
    <SectionTitle
      tag='h2'
      variant='border'
      text='Fortune Analytics Spreadsheets'
      className='title-analytics'
      data-cy={RANKING_ANALYTICS.RA_TITLE}
    />
    <S.Container>
      {analyticsItems.map((item) => (
        <S.Card
          key={item.id}
          data-cy={RANKING_ANALYTICS.RA_ITEM}
        >
          <S.Content>
            <S.ImageWrapper>
              <NextCustomImage
                alt={item.caption}
                src={item.image}
                width='88'
                height='88'
                data-cy={RANKING_ANALYTICS.IMAGE}
              />
            </S.ImageWrapper>
            <div>
              <S.Title data-cy={RANKING_ANALYTICS.RA_TITLE}>{item.title}</S.Title>
              <S.Description data-cy={RANKING_ANALYTICS.RA_DESCRIPTION}>{item.description}</S.Description>
            </div>
          </S.Content>

          <Link
            href={item.link}
            type='tertiaryButton'
            className='link-analytics'
            data-cy={RANKING_ANALYTICS.RA_CTA}
          >
            LEARN MORE
          </Link>
        </S.Card>
      ))}
    </S.Container>
    <Link
      href='https://fortune.com/analytics/packages'
      type='secondaryButton'
      size={{ default: 'large', mdMax: 'small' }}
      className='explore-button'
      hasRightArrow={false}
      dataCy={RANKING_ANALYTICS.RA_CTA_EXPLORE}
    >
      EXPLORE PACKAGE OPTIONS
    </Link>
  </S.Wrapper>
);

export default RankingAnalytics;
