import Link from 'components/Globals/Base/Link';
import * as S from './CompanyUnrankedStyles';

export interface CompanyUnrankedProps {
  name: string;
  slug: string;
  isLast: boolean;
  isLastSecondColumn: boolean;
  classModifier?: string;
  dataCy: string;
}

const CompanyUnranked = ({
  name,
  slug,
  isLast,
  isLastSecondColumn,
  classModifier = '',
  dataCy,
}: CompanyUnrankedProps) => {
  const isFullPath = slug.startsWith('/company/');
  const href = isFullPath ? slug : `/company/${slug}/`;

  return (
    <S.Wrapper
      className={`${classModifier}`}
      data-cy={dataCy}
    >
      <Link href={href}>
        <S.Name
          className={`${classModifier}`}
          isLast={isLast}
          isLastSecondColumn={isLastSecondColumn}
        >
          {name}
        </S.Name>
      </Link>
    </S.Wrapper>
  );
};

export default CompanyUnranked;
