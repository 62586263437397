import SectionTitle from 'components/Globals/SectionTitle';
import { NextCustomImage } from 'components/Image';
import { Feature } from 'interfaces/content/Ranking';
import Link from 'components/Globals/Base/Link';
import RawHtml from 'utils/miscUtils/rawHtml';
import { VISUALIZE } from 'constants/testsIds/rankingV2Page';
import * as S from './VisualizeF500Styles';

interface VisualizeF500Props {
  feature: Feature;
  mainTitle: string;
}

const VisualizeF500 = ({ feature, mainTitle }: VisualizeF500Props) => {
  const { content, image, title, url } = feature;
  if (content === null || image === null || title === null || url === null) {
    return null;
  }
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <SectionTitle
          tag='h2'
          text={mainTitle}
          variant='border'
          titleDataCy={VISUALIZE.TITLE}
        />
        <S.Content data-cy={VISUALIZE.CONTENT}>{RawHtml({ html: content })}</S.Content>
        <Link
          href={url}
          target='_blank'
          type='tertiaryButton'
          data-cy={VISUALIZE.BUTTON_LINK}
        >
          EXPLORE
        </Link>
      </S.ContentWrapper>
      <Link
        href={url}
        target='_blank'
        data-cy={VISUALIZE.IMAGE_LINK}
      >
        <NextCustomImage
          alt={title}
          src={image}
          width={1024}
          height={683}
          data-cy={VISUALIZE.IMAGE}
          className='adaptive-img'
        />
      </Link>
    </S.Wrapper>
  );
};

export default VisualizeF500;
