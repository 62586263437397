import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getBodyStyle, Theme } from 'styles/theme';

export const MethodologyWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  scroll-margin-top: calc(var(--ld-height, 0) + 72px);

  @media (${breakpoint.mdMin}) {
    max-width: 90vw;
  }

  .panel {
    @media (${breakpoint.mdMin}) {
      min-height: 350px;
    }

    .section-content {
      ${getBodyStyle('graphikCompact', { default: 'default' })};

      @media (${breakpoint.xlMin}) {
        padding: 24px 212px 0;
        margin: 0 auto;
      }

      &.is-hidden {
        padding: 0;
      }
    }    
  }
`;

export default MethodologyWrapper;
