/* eslint-disable react/no-array-index-key */
import SectionTitle from 'components/Globals/SectionTitle';
import { FranchiseListItemPreview, ListTeaser } from 'interfaces/content/Ranking';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import CompanyRank from 'components/Company/InRankings/CompanyRank';
import Link from 'components/Globals/Base/Link';
import CompanyUnranked from '../CompanyUnranked';
import * as S from './RankingListStyles';

interface RankingListProps {
  title: string;
  lists: {
    title?: string;
    items: FranchiseListItemPreview[];
  }[];
  listTeaser: ListTeaser;
  listType: string;
  year?: string;
}

const RankingList = ({ lists, title, listTeaser, listType, year }: RankingListProps) => {
  const totalItems = lists[0].items.length;
  const halfLength = Math.ceil(totalItems / 2);

  const firstColumnRank = lists[0].items.slice(0, halfLength);
  const secondColumnRank = lists[0].items.slice(halfLength);

  const isRankedList = listTeaser.hideRanking;
  const yearInTitle = year ? `(${year})` : '';

  return (
    <S.Wrapper
      isRanked={isRankedList}
      data-cy={RANKING_V2_PAGE.RANKING_LIST}
    >
      <SectionTitle
        tag='h2'
        text={isRankedList ? `${title} ${yearInTitle}` : `${title} Ranking ${yearInTitle}`}
        variant='border'
        titleDataCy={RANKING_V2_PAGE.RANKING_LIST_TITLE}
      />
      <S.ColumnWrapper isRanked={isRankedList}>
        {[firstColumnRank, secondColumnRank].map((column, columnIndex) => (
          <S.Column
            key={`column-${columnIndex}`}
            isRanked={isRankedList}
          >
            {column.map((item, index) =>
              (isRankedList ? (
                <CompanyUnranked
                  key={item.slug}
                  slug={item.slug}
                  name={item.title}
                  isLast={index === column.length - 1}
                  isLastSecondColumn={columnIndex === 1 && index === column.length - 1}
                  dataCy={RANKING_V2_PAGE.RANKING_LIST_ITEM}
                />
              ) : (
                <CompanyRank
                  key={item.slug}
                  slug={item.slug}
                  rank={item.rank}
                  name={item.title}
                  hasOpacity={false}
                  dataCy={RANKING_V2_PAGE.RANKING_LIST_ITEM}
                  classModifier='ranking-list'
                />
              )))}
          </S.Column>
        ))}
      </S.ColumnWrapper>
      {isRankedList && listType === 'list' && (
        <S.ExploreListBtn>
          <Link
            className='black-button'
            href='#search-section'
            key='explore-the-list'
            type='tertiaryButton'
            hasRightArrow={false}
          >
            EXPLORE THE LIST
          </Link>
        </S.ExploreListBtn>
      )}
    </S.Wrapper>
  );
};

export default RankingList;
