import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 -20px;

  @media (${breakpoint.mdMin}) {
    gap: 48px;
    margin: 0 -32px;
  }

  @media (${breakpoint.lgMin}) {
    gap: 48px;
    margin: 0 -24px;
  }

  @media (${breakpoint.xlMin}) {
    margin: 0 -48px;
  }

  @media (${breakpoint.xxlMin}) {
    gap: 40px;
    margin: 0 -40px;
  }

  .see-all-rankings {
    margin: 0 20px 0 20px;

    @media (${breakpoint.mdMin}) {
      margin: 0 auto;
    }
  }
`;

export const TitleWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('backgroundTertiary')};
  position: absolute;
  width: 100%;
  height: 150px;
  padding: 40px 20px;

  @media (${breakpoint.mdMin}) {
    padding: 40px 32px;
  }

  @media (${breakpoint.lgMin}) {
    height: 165px;
  }

  @media (${breakpoint.xlMin}) {
    padding: 40px 48px;
  }

  .title {
    color: ${getColor('textWhite')};

    @media (${breakpoint.mdMin}) {
      font-size: 2rem;
    }
  }
`;

export const CardWrapper = styled.div<{ theme: Theme }>`
  display: grid;
  padding: 0px 20px;
  margin-top: 90px;
  row-gap: 16px;

  @media (${breakpoint.mdMin}) {
    padding: 0 32px;
    margin-top: 120px;
  }

  @media (${breakpoint.lgMin}) {
    column-gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    > *:nth-child(3) {
      left: 0;
    }
  }

  @media (${breakpoint.xlMin}) {
    padding: 0 48px;
    column-gap: 40px;
  }

  .ranking-item {
    background-color: ${getColor('backgroundPrimary')};


    .bottom-container {
      text-align: center;
    }
  }
`;
