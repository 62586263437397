import { Tag, Subtitle, Wrapper } from './TitleStyles';

export interface Props {
  tag: string;
  text: string;
  subtitle?: string;
  variant: 'border' | 'no-border';
  className?: string;
  titleDataCy?: string;
}

const Title = ({ tag, text, subtitle = '', variant = 'border', className = '', titleDataCy = '' }: Props) => {
  const getTitle = (type: 'border' | 'no-border') => (
    <Tag
      as={tag}
      $fontFamily='graphikCond'
      $size={{ default: 'level3', lgMin: 'level2' }}
      $variant={type}
      className={className}
      data-cy={titleDataCy}
    >
      {text}
    </Tag>
  );

  if (subtitle !== '') {
    return (
      <Wrapper>
        <Subtitle>{subtitle}</Subtitle>
        {getTitle('no-border')}
      </Wrapper>
    );
  }
  return getTitle(variant);
};

export default Title;
