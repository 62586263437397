import { RelatedRanking } from 'interfaces/content/Ranking';
import SectionTitle from 'components/Globals/SectionTitle';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import Link from 'components/Globals/Base/Link';
import CardItem from './CardItem';
import * as S from './MoreRankingsStyle';

interface MoreRankingsProps {
  relatedRankings: RelatedRanking[];
}

const MoreRankings = ({ relatedRankings }: MoreRankingsProps) => {
  const moreRankings = relatedRankings || [];

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <SectionTitle
          tag='h2'
          text='More Fortune Rankings'
          variant='border'
          className='title'
          titleDataCy={RANKING_V2_PAGE.MORE_RANKINGS_MAIN_TITLE}
        />
      </S.TitleWrapper>
      <S.CardWrapper>
        {moreRankings.map((ranking, index) => (
          <CardItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${ranking.slug}-${index}`}
            title={ranking.title}
            description=''
            years={ranking.years || []}
            slug={ranking.permalink}
            image={ranking.image.src}
            buttonTitle={`VIEW ${ranking.years[0].year} LIST`}
            hasArrow
            imageAlt={ranking.image.alt}
            postId={ranking.databaseId}
            className='ranking-item'
          />
        ))}
      </S.CardWrapper>
      <Link
        href='/ranking/'
        type='primaryButton'
        size={{ default: 'large', mdMax: 'small' }}
        className='see-all-rankings'
        hasRightArrow={false}
        dataCy={RANKING_V2_PAGE.SEE_ALL_CTA}
      >
        See All Rankings
      </Link>
    </S.Wrapper>
  );
};

export default MoreRankings;
