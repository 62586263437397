import Link from 'components/Globals/Base/Link';
import { Year } from 'interfaces/content/Ranking';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import { YearListWrapper, StyledDiv } from './yearListStyles';

export interface YearListProps {
  years: Year[];
  listId: number;
  className?: string;
}
const YearList = ({ years, listId, className = '' }: YearListProps) => {
  const maxDisplayYears = 4;
  const moreYears = years.slice(3);

  return (
    <YearListWrapper className={className}>
      <div className='years'>
        {years.slice(1, maxDisplayYears).map((y) => (
          <Link
            className='year'
            key={y.year}
            href={y.permalink}
            dataCy={RANKING_V2_PAGE.MORE_RANKINGS_YEAR_LINK}
          >
            {y.year}
          </Link>
        ))}
      </div>
      {years.length > 3 ? (
        <div
          className='wrapper'
          data-cy={RANKING_V2_PAGE.MORE_RANKINGS_MORE_YEARS}
        >
          <button
            type='button'
            className='more'
          >
            more
          </button>
          <div
            id={`yearsList_${listId}`}
            className='more-years'
          >
            {moreYears.slice(1, maxDisplayYears).map((y) => (
              <Link
                className='more-year'
                key={y.year}
                href={y.permalink}
                dataCy={RANKING_V2_PAGE.MORE_RANKINGS_MORE_YEARS_LINK}
              >
                {y.year}
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <StyledDiv />
      )}
    </YearListWrapper>
  );
};

export default YearList;
