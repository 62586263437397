import styled from 'styled-components';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.p<{ theme: Theme }>`
  .ranking-link {
    display: flex;
    text-decoration: none;
    
    &:not(.no-opacity) {
      opacity: 0.6;
    }

    &:hover,
    &:hover > * {
      text-decoration: none;
      opacity: 1;
      color: inherit;

      span:nth-child(1) {
        color: ${getColor('textWhite')};
      }
    }
    &:active {
      span:nth-child(2) {
        background-color: ${getColor('neutralLightGray3')};
        color: ${getColor('textPrimary')};
      }
    }

    &:focus > * {
      text-decoration: none;
    }

    &.no-opacity {
      &:hover {
        span:nth-child(2) {
          background-color: ${getColor('neutralLightGray3')};
          color: ${getColor('textPrimary')};
        }
      }
    }
  }
`;

export const Line = styled.span`
  opacity: 1;
  display: flex;
`;

export const Name = styled.span<{ theme: Theme }>`
  display: flex;
  padding: 8px 24px;
  align-items: center;
  background-color: ${getColor('surfaceSecondary')};
  width: 100%;
  ${getBodyStyle('graphikCond', { default: 'large', lgMin: 'xlarge' }, 'semiBold')};

  &.active {
    background-color: ${getColor('neutralLightGray3')};
    color: ${getColor('textPrimary')};
  }
`;

export const Rank = styled.span<{ theme: Theme }>`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${getColor('brandSecondary')};
  width: 83px;
  ${getBodyStyle('graphikCond', { default: 'xlarge' }, 'semiBold')};
  color: ${getColor('textWhite')};

  span {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
  }
`;
