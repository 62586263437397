export const analyticsItems = [
  {
    caption: 'A Fortune 500 image that shows the number 500',
    description: 'Get the data behind the original and ultimate benchmark of business success',
    id: 0,
    image: 'https://fortune.com/img-assets/wp-content/uploads/2024/10/fortune500.png',
    link: 'https://fortune.com/analytics/fortune-500',
    title: 'Fortune 500',
  },
  {
    caption: 'A Fortune Global 500 image that shows the number 500',
    description: 'Get the data behind the original and ultimate benchmark of business success',
    id: 1,
    image: 'https://fortune.com/img-assets/wp-content/uploads/2024/10/global500.png',
    link: 'https://fortune.com/analytics/fortune-global-500',
    title: 'Global 500',
  },
  {
    caption: 'A Fortune 1000 image that shows the number 1000',
    description: 'Get the data behind the original and ultimate benchmark of business success',
    id: 2,
    image: 'https://fortune.com/img-assets/wp-content/uploads/2024/10/fortune1000.png',
    link: 'https://fortune.com/analytics/fortune-1000',
    title: 'Fortune 1000',
  },
];

export default analyticsItems;
