import Link from 'components/Globals/Base/Link';
import Checkmark from 'components/Globals/CheckMark';
import { Name, Rank, Wrapper, Line } from './CompanyRankStyles';

export interface CompanyRankProps {
  name: string;
  rank: string | number;
  slug: string;
  active?: boolean;
  hasOpacity?: boolean;
  classModifier?: string;
  dataCy?: string;
}

const RankingItem = ({ name, rank, slug, active, classModifier = '', hasOpacity = true, dataCy }: CompanyRankProps) => {
  const isFullPath = slug.startsWith('/company/');
  const href = isFullPath ? slug : `/company/${slug}/`;

  return (
    <Wrapper
      data-cy={dataCy}
      className={`${classModifier}`}
    >
      {!active ? (
        <Link
          className={`ranking-link ${hasOpacity ? '' : 'no-opacity'}`}
          type='white'
          href={href}
        >
          <Rank className={classModifier}>{rank || <Checkmark />}</Rank>
          <Name className={`${classModifier}`}>{name}</Name>
        </Link>
      ) : (
        <Line>
          <Rank className={classModifier}>{rank || <Checkmark />}</Rank>
          <Name className={`active ${classModifier}`}>{name}</Name>
        </Line>
      )}
    </Wrapper>
  );
};

export default RankingItem;
