import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.p`
`;

export const Name = styled.span<{
  theme: Theme;
  isLast: boolean;
  isLastSecondColumn: boolean;
}>`
  display: flex;
  ${getBodyStyle('graphikCond', { default: 'large', lgMin: 'xlarge' }, 'semiBold')};
  padding-left: 8px;
  padding-bottom: 8px;

  border-bottom: ${(props) => (props.isLastSecondColumn ? 'none' : `1px solid ${getColor('borderSecondary')(props)}`)};

  @media(${breakpoint.lgMin}) {
    border-bottom: ${(props) => (props.isLast ? 'none' : `1px solid ${getColor('borderSecondary')(props)}`)};
  }
`;
