import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme; isRanked: boolean }>`
  display: grid;
  background-color: ${getColor('textWhite')};
  box-shadow: 0 0px 24px 8px ${getColor('neutralLightGray3')}40;
  font-variant-numeric: tabular-nums;
  padding: 20px 16px;
  gap: 24px;

  @media(${breakpoint.mdMin}) {
    gap: 32px;

    h2 {
      font-size: 2rem;
    }
  }

  @media(${breakpoint.mdMin}) {
    padding: 40px;
  }

  @media(${breakpoint.lgMin}) {
    gap: ${({ isRanked }) => (isRanked ? '32px' : '24px')};
  }
`;

export const ColumnWrapper = styled.div<{ theme: Theme; isRanked: boolean }>`
  display: grid;
  gap: ${({ isRanked }) => (isRanked ? '8px' : '16px')};
  
  @media(${breakpoint.lgMin}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ isRanked }) => (isRanked ? '32px' : '40px')};
  }
`;

export const Column = styled.div<{ theme: Theme; isRanked: boolean }>`
    display: grid;
    gap: ${({ isRanked }) => (isRanked ? '8px' : '24px')};

    @media(${breakpoint.lgMin}) {
      gap: ${({ isRanked }) => (isRanked ? '8px' : '16px')};
    }

    span:nth-child(2) {
      &.ranking-list {
        font-size: 1.25rem;
        padding: 8px 16px;
      }
    }
`;

export const ExploreListBtn = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  border-top: 1px solid ${getColor('borderPrimary')};
`;
