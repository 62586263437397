import { Grid } from 'interfaces/content/Ranking';
import SectionTitle from 'components/Globals/SectionTitle';
import { TimeAgo } from 'components/ArticleV2/Latest/LatestStyles';
import { PostContentItem } from 'components/ContentItem';
import useTimeAgo from 'hooks/useTimeAgo';
import useBreakpoint from 'hooks/useBreakpoint';
import Slider from 'react-slick';
import * as S from './GridContentModuleV2Styles';

interface GridContentModuleV2Props {
  grid: Grid;
  dataCy: string;
}
const GridContentModuleV2 = ({ grid, dataCy }: GridContentModuleV2Props) => {
  const { title, topics } = grid;
  const fourArticles = topics?.slice(0, 4);
  const isMobile = useBreakpoint('mdMax');

  const sliderSettings = {
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1.2,
        },
      },
    ],
  };

  const trackingData = {
    contentPlacementCD: 'related content',
    eventCategory: 'article',
  };

  return (
    <S.Wrapper>
      <SectionTitle
        text={title || 'More coverage'}
        tag='h2'
        variant='border'
        className='title'
      />
      {isMobile ? (
        <S.SliderWrapper data-cy={dataCy}>
          <Slider {...sliderSettings}>
            {fourArticles?.map((article) => {
              const postContentItemProps = {
                authors: article.authors,
                date: article.date,
                eyebrowSectionLabel: article.sectionName,
                eyebrowSectionLink: article.sectionUri,
                image: article.image,
                title: article.title,
                titleLink: article.permalink,
              };

              // eslint-disable-next-line react-hooks/rules-of-hooks
              const date = useTimeAgo(article.date);

              return (
                <div key={article.databaseId}>
                  <div className='slider-card'>
                    <TimeAgo className='time-ago-wrapper'>{date}</TimeAgo>
                    <PostContentItem
                      {...postContentItemProps}
                      className='post-list'
                      trackingData={{
                        ...trackingData,
                        eventAction: `${article.sectionName}`,
                        eventLabel: `${article.title}`,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </S.SliderWrapper>
      ) : (
        <S.ArticlesContainer data-cy={dataCy}>
          {fourArticles?.map((article) => {
            const postContentItemProps = {
              authors: article.authors,
              date: article.date,
              eyebrowSectionLabel: article.sectionName,
              eyebrowSectionLink: article.sectionUri,
              image: article.image,
              title: article.title,
              titleLink: article.permalink,
            };

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const date = useTimeAgo(article.date);

            return (
              <div key={article.databaseId}>
                <TimeAgo className='time-ago-wrapper'>{date}</TimeAgo>
                <PostContentItem
                  {...postContentItemProps}
                  className='post-list'
                  trackingData={{
                    ...trackingData,
                    eventAction: `${article.sectionName}`,
                    eventLabel: `${article.title}`,
                  }}
                />
              </div>
            );
          })}
        </S.ArticlesContainer>
      )}
    </S.Wrapper>
  );
};

export default GridContentModuleV2;
