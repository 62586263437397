import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import { commonColors } from 'styles/colors';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 12px; // + 4px border = 16px;
  border-left: 4px solid ${getColor('borderAccentSecondary')};

  h2:not(:only-child) {
    border-left: none;
    padding-left: 0;
  }

  @media (${breakpoint.mdMin}) {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

export const Tag = styled(Heading)<{ $variant: 'border' | 'no-border' }>`
  border-left: ${({ $variant }) =>
    ($variant === 'border' ? `4px solid ${commonColors.borderAccentSecondary}` : 'none')};
  padding-left: ${({ $variant }) => ($variant === 'border' ? '12px' : 0)};

  @media (${breakpoint.mdMin}) {
    border-left: 4px solid ${getColor('borderAccentSecondary')};
  }
`;

export const Subtitle = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
  color: ${getColor('textLink')};

  display: block;

  @media (${breakpoint.mdMin}) {
    display: none;
  }
`;
