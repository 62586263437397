import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const Wrapper = styled.div<{ theme: Theme }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .slider-container {
    position: relative;
  }
  
  .slick-slider {
    padding-top: 24px;

    .slick-prev {
      left: -5px;
      z-index: 1;
      position: absolute;
      top: 120px;
      
      &.slick-disabled {
        border-color: ${getColor('neutralLightGray3')};
      }
    }

    .slick-next {
      right: 0;
      z-index: 1;
      position: absolute;
      top: 120px;

      &.slick-disabled {
        border-color: ${getColor('neutralLightGray3')};
      }

      @media (${breakpoint.xxlMin}) {
        right: -8px;
      }
    }
  }

  .slick-list {
    padding-bottom: 24px;
  }

  .slick-track {
    display: flex;

    @media (${breakpoint.mdMin}) {
      gap: 40px;
    }

    .slick-slide {
      padding-right: 20px;

      &:last-of-type {
        padding-right: 0;
      }

      @media (${breakpoint.mdMin}) {
        padding-right: 0;
      }
    }
  }
`;

export const Card = styled.div<{ theme: Theme; $isExpanded: boolean }>`
  position: relative;

  .checkmark-badge {
    padding-top: 4px;
  }
  
  .title {
    border-left: none;

    @media (${breakpoint.mdMin}) {
      font-size: 1.25rem;
    }

    @media (${breakpoint.lgMin}) {
      font-size: 1.5rem;
    }
  }

  .button {
    position: absolute;
    right: 8px;
    bottom: -24px;
  }

  .read-more-button {
    color: ${getColor('neutralBlack')};

    &:after {
      position: relative;
      border-color: ${getColor('borderBlack')};
      left: 4px;
      top: ${({ $isExpanded }) => ($isExpanded ? '4px' : '-2px')};
      transform: rotate(${({ $isExpanded }) => ($isExpanded ? '225deg' : '45deg')});
    }
  }
`;

export const Description = styled.div<{ theme: Theme; $isExpanded: boolean }>`
  ${getBodyStyle('graphikCond', { default: 'default' })};
  margin: 16px 0 8px 0;

  ${({ $isExpanded }) =>
    !$isExpanded &&
    css`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (${breakpoint.mdMin}) {
      -webkit-line-clamp: 4;
    }
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 8px;

  h2 {
    font-size: 1.5rem;
  }
`;

export const Rank = styled.div<{ theme: Theme }>`
  background-color: ${getColor('accentSecondary')};
  ${getBodyStyle('graphikCond', { default: 'large' }, 'semiBold')};
  color: ${getColor('textWhite')};
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  font-size: 14px;
`;
